import { action, observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { TransportCompany } from './TransportCompany';
import { Thread } from 'store/Threads/Thread';
import { LoadStore } from './Load';
import { UnloadStore } from './Unload';
import { TransportBatchStore } from './TransportBatch';

export class TransportOrder extends Model {
    static backendResourceName = 'transport_order';
    static omitFields = [ 'latestUnresolvedThreadMessage', 'numberOfUnresolvedThreads', 'startDate', 'endDate','cbm', 'maxCbm', 'partCbm', 'rejectReason'];
    static STATUSES = ['draft', 'planned', 'confirmed', 'declined'];

    @observable id = null;
    @observable status = 'draft';

    @observable threads = this.relation(Thread);

    // Annotations
    @observable latestUnresolvedThreadMessage;
    @observable numberOfUnresolvedThreads;
    @observable startDate = null;
    @observable endDate = null;
    @observable cbm = null;
    @observable maxCbm = null;
    @observable partCbm = null;
    @observable rejectReason = '';
    @observable licensePlate = '';

    @observable _emailTemplate = null;
    @observable _emailUserInput;
    @observable _tokenAccepted;


    sendTransportRequest(emailTemplate = null, ) {
        let data = {
           'email_template': emailTemplate.id,
        }
        return this.api.post(`${this.url}send_transport_request/`, data).then(action(res => {

            window.viewStore.showSaveNotification();

        }))
            .catch(action(err => {
                // if (err.valErrors) {
                //     this.parseValidationErrors(err.valErrors);
                // }
                throw err;
            }));
    }

    verifyToken(emailToken) {
        const params = {
            token: emailToken.token
        }
        return this.api.get(`${this.url}verify_token/`, {}, { params: params }).then(action(res => {
            if (res.valid) {
                emailToken._valid = 'valid';
            } else {
                emailToken._valid = false
            }
        }))
            .catch(action(err => {
                // if (err.valErrors) {
                //     this.parseValidationErrors(err.valErrors);
                // }
                throw err;
            }));
    }

    deliverDropship() {
        let data = {};

        return this.api.post(`${this.url}deliver_dropshipment/`, data).then(action(res => {

            window.viewStore.showSaveNotification();

        }))
            .catch(action(err => {
                // if (err.valErrors) {
                //     this.parseValidationErrors(err.valErrors);
                // }
                throw err;
            }));
    }


    relations() {
        return {
            transportCompany: TransportCompany,
            transportBatches: TransportBatchStore,
            loads: LoadStore,
            unloads: UnloadStore,
        };
    }

    casts() {
        return {
            startDate: Casts.date,
            endDate: Casts.date,
        };
    }
}

export class TransportOrderStore extends Store {
    Model = TransportOrder;
    static backendResourceName = 'transport_order';
}
