import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { SalesOrder } from './SalesOrder';
import { DistributionOrderLineStore } from './DistributionOrderLine';
import { TransportOrderStore } from './TransportOrder';

export class DistributionOrder extends Model {
    static backendResourceName = 'distribution_order';
    static omitFields = ['deliveryIssue', 'transportOrders', 'hasShortage', 'deliveryStatus', 'expectedDeliveryDate', 'blocked'];

    @observable id = null;
    @observable status = 'planned';
    @observable customerType = 'business';
    @observable deliveryDate = null;
    @observable deliveryIssue = null;
    @observable directToCustomer = false;
    @observable hasShortage = false;
    @observable deliveryStatus = null;
    @observable expectedDeliveryDate = null;
    @observable blocked = false;

    relations() {
        return {
            salesOrder: SalesOrder,
            lines: DistributionOrderLineStore,
            transportOrders: TransportOrderStore,
        };
    }

    casts() {
        return {
            deliveryDate: Casts.date,
            expectedDeliveryDate: Casts.date,
        }
    }
}

export class DistributionOrderStore extends Store {
    Model = DistributionOrder;
    static backendResourceName = 'distribution_order';
}
