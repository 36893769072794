import { action, observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { SalesOrder } from './SalesOrder';
import { Supplier } from './Supplier';
import { PurchaseOrderLineStore } from './PurchaseOrderLine';
import { Thread, ThreadStore } from 'store/Threads/Thread';
import { ArticleStore } from 'store/Article';

export class PurchaseOrder extends Model {
    static backendResourceName = 'purchase_order';
    static omitFields = [
        'readyStatus',
        'minLoadDate',
        'maxLoadDate',
        'minUnloadDate',
        'maxUnloadDate',
        'latestUnresolvedThreadMessage',
        'numberOfUnresolvedThreads',
    ];

    @observable id = null;
    @observable number = null;
    @observable accepted = false;
    @observable receiptStatus = 'open';
    @observable orderDate = null;
    @observable productionDate = null;
    @observable loadDate = null;
    @observable unloadDate = null;

    @observable readyStatus = null;
    @observable minLoadDate = null;
    @observable maxLoadDate = null;
    @observable minUnloadDate = null;
    @observable maxUnloadDate = null;
    @observable latestUnresolvedThreadMessage = null;
    @observable numberOfUnresolvedThreads = null;

    get noteThread() {
        return this.threads.length > 0 ? this.threads.at(0) : new Thread({ purchaseOrder: { id: this.id } }, { relations: ['notes', 'purchaseOrder'] })
    }

    labelUrl(finished=undefined) {
        if (!this.lines){
            return
        }

        const articleCounts = []

        this.lines.forEach(line => {
            let amountString = ''
            if (finished === undefined) {
                amountString = `${line.open}x${line.article.id}`
            } else {
                amountString = `${finished ? line.finishedQuantity : line.unfinishedQuantity}x${line.article.id}`
            }
            articleCounts.push(amountString)
        })

        return ArticleStore.labelUrl(articleCounts)
    }

    get exactUrl() {
        if (!this.number){
            return null;
        }
        return `/api${this.url}exact_url/`
    }


    finishProduction(lines=null) {
        if (lines) {
            const data = { lines: [] };
            lines.forEach(line=> {
                data.lines.push({ id: line.id, quantity: line._actionQuantity })
            });
            return this.api.post(`${this.url}finish_production/`, data).then(action(res => {
                window.viewStore.showSaveNotification();

            }))
                .catch(action(err => {
                    // if (err.valErrors) {
                    //     this.parseValidationErrors(err.valErrors);
                    // }
                    throw err;
                }));
        }
        return this.api.post(`${this.url}finish_production/`).then(action(res => {

            window.viewStore.showSaveNotification()

        }))
            .catch(action(err => {
                // if (err.valErrors) {
                //     this.parseValidationErrors(err.valErrors);
                // }
                throw err;
            }));
    }

    resetProduction(lines=null) {
        if (lines) {
            const data = { lines: [] };
            lines.forEach(line=> {
                data.lines.push({ id: line.id, quantity: line._actionQuantity })
            });
            return this.api.post(`${this.url}reset_production/`, data).then(action(res => {
                window.viewStore.showSaveNotification();

            }))
                .catch(action(err => {
                    // if (err.valErrors) {
                    //     this.parseValidationErrors(err.valErrors);
                    // }
                    throw err;
                }));
        }
        return this.api.post(`${this.url}reset_production/`).then(action(res => {

            window.viewStore.showSaveNotification()

        }))
            .catch(action(err => {
                // if (err.valErrors) {
                //     this.parseValidationErrors(err.valErrors);
                // }
                throw err;
            }));
    }

    dropship() {
        let data = {
            'load_planned_at': this._transportOrder.loads.at(0).plannedAt,
            'unload_planned_at': this._transportOrder.unloads.at(0).plannedAt,
            'to_transport_lines': []
        };

        if (this.lines) {
            this.lines.forEach(line => {
                if (line._actionQuantity) {
                    data['to_transport_lines'].push({
                        'purchase_order_line': line.id,
                        'to_transport_quantity': line._actionQuantity
                    });
                }
            });
        }
        return this.api.post(`${this.url}dropship/`, data)
            .catch(action(err => {
                throw err;
            }));
    }

    relations() {
        return {
            salesOrder: SalesOrder,
            supplier: Supplier,
            lines: PurchaseOrderLineStore,
            threads: ThreadStore
        };
    }

    casts() {
        return {
            productionDate: Casts.date,
            orderDate: Casts.date,
            loadDate: Casts.date,
            unloadDate: Casts.date,
            minLoadDate: Casts.date,
            maxLoadDate: Casts.date,
            minUnloadDate: Casts.date,
            maxUnloadDate: Casts.date,
        }
    }
}

export class PurchaseOrderStore extends Store {
    Model = PurchaseOrder;
    static backendResourceName = 'purchase_order';
}
