import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { PurchaseOrderStore } from './PurchaseOrder';
import { ArticleStore } from './Article';

export class Supplier extends Model {
    static backendResourceName = 'supplier';

    @observable id = null;
    @observable code = '';
    @observable name = '';

    @observable emailAddress = '';
    @observable phoneNumber = '';

    @observable street = '';
    @observable number = '';
    @observable numberSuffix = '';
    @observable zipCode = '';
    @observable city = '';
    @observable country = '';

    @observable dropshipment = false;

    relations() {
        return {
            purchaseOrders: PurchaseOrderStore,
            articles: ArticleStore,
        };
    }
}

export class SupplierStore extends Store {
    Model = Supplier;
    static backendResourceName = 'supplier';
}
