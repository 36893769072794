import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { DistributionOrderLine } from './DistributionOrderLine';
import { JanKredietOrder } from './JanKredietOrder';

export class JanKredietOrderLine extends Model {
    static backendResourceName = 'jan_krediet_order_line';

    @observable id = null;

    relations() {
        return {
            order: JanKredietOrder,
            distributionOrderLine: DistributionOrderLine,
        };
    }
}

export class JanKredietOrderLineStore extends Store {
    Model = JanKredietOrderLine;
    static backendResourceName = 'jan_krediet_order_line';
}
