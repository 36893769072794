import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { DistributionOrderLine } from './DistributionOrderLine';
import { JanKredietOutboundOrder } from './JanKredietOutboundOrder';

export class JanKredietOutboundOrderLine extends Model {
    static backendResourceName = 'jan_krediet_outbound_order_line';

    @observable id = null;

    relations() {
        return {
            outboundOrder: JanKredietOutboundOrder,
            distributionOrderLine: DistributionOrderLine,
        };
    }
}

export class JanKredietOutboundOrderLineStore extends Store {
    Model = JanKredietOutboundOrderLine;
    static backendResourceName = 'jan_krediet_outbound_order_line';
}
