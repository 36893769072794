import { observable } from 'mobx';
import { Thread } from './Thread';
import { User } from 'store/User';
import { Model, Store, Casts } from 'store/Base';
import { Moment } from 'moment';

export class Note extends Model {
    static backendResourceName = 'note';

    @observable id: number | null = null;

    @observable createdAt: null | Moment = null;
    @observable contents = '';

    @observable thread = this.relation(Thread);

    @observable user = this.relation(User);


    casts() {
        return {
            createdAt: Casts.datetime,
        };
    }

}


export class NoteStore extends Store {
    static backendResourceName = 'note';
    Model = Note;
}
