import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { DistributionOrder } from './DistributionOrder';
import { Stock } from './Stock';
import { TransportBatch } from './TransportBatch';
import { Article } from './Article';
import { SalesOrderLine } from './SalesOrderLine';
import { JanKredietOrderLine } from './JanKredietOrderLine';
import { JanKredietOutboundOrderLine } from './JanKredietOutboundOrderLine';

export class DistributionOrderLine extends Model {
    static backendResourceName = 'distribution_order_line';
    static omitFields = ['deliveryStock', 'synced', 'deliveryStatus', 'expectedDeliveryDate'];

    @observable id = null;
    @observable quantity = 0;
    @observable synced = false;
    @observable deliveryStatus = null;
    @observable expectedDeliveryDate = null;

    relations() {
        return {
            distributionOrder: DistributionOrder,
            salesOrderLine: SalesOrderLine,
            article: Article,
            transportBatch: TransportBatch,
            deliveryStock: Stock,
            janKredietOrderLine: JanKredietOrderLine,
            janKredietOutboundOrderLine: JanKredietOutboundOrderLine,
        };
    }

    casts() {
        return {
            expectedDeliveryDate: Casts.date,
        };
    }
}

export class DistributionOrderLineStore extends Store {
    Model = DistributionOrderLine;
    static backendResourceName = 'distribution_order_line';
}
