import { observable, runInAction } from 'mobx';
import { Model, Store } from 'store/Base';
import { JanKredietOutboundOrderLineStore } from './JanKredietOutboundOrderLine';
import { DistributionOrder } from './DistributionOrder';
import { showSaveNotification } from 'helpers/notification';

export class JanKredietOutboundOrder extends Model {
    static backendResourceName = 'jan_krediet_outbound_order';
    static omitFields = ['reference'];
    static STATUSES = ['to_sync', 'synced', 'sync_error', 'done', 'to_cancel', 'await_cancel', 'cancelled', 'cancel_error'];

    @observable id = null;
    @observable status = 'to_sync';
    @observable error = null;
    @observable reference = null;
    @observable janKredietId = null;
    @observable janKredietOutboundId = null;
    @observable janKredietOutboundStatus = null;
    @observable hasError = false;
    @observable deliveredWhileBlocked = false;

    async resync() {
        await this.wrapPendingRequestCount(this.api.post(`${this.url}resync/`));
        runInAction(() => {
            showSaveNotification();
            this.status = 'to_sync';
            this.error = null;
        });
    }

    async ignore() {
        await this.wrapPendingRequestCount(this.api.post(`${this.url}ignore/`));
        runInAction(() => {
            showSaveNotification();
            this.error = null;
        });
    }

    relations() {
        return {
            distributionOrder: DistributionOrder,
            lines: JanKredietOutboundOrderLineStore,
        };
    }
}

export class JanKredietOutboundOrderStore extends Store {
    Model = JanKredietOutboundOrder;
    static backendResourceName = 'jan_krediet_outbound_order';
}
