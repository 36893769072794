import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable, set, remove, computed, action, runInAction } from 'mobx';
import { Store } from 'mobx-spine';
import { Link } from 'react-router-dom';
import { Dimmer, Loader, Form, Table, Icon, Popup, Button, Menu, Input, Checkbox, Dropdown, Ref, Progress } from 'semantic-ui-react';
import moment from 'moment';
import { ACTION_DELAY, snakeToCamel, camelToSnake, BOOL_OPTIONS } from '../../helpers';
import bindUrlParams, { encodeUrlByPathname } from '../../helpers/bindUrlParams';
import { ContentContainer, Content, Toolbar, RadioButtons } from 're-cy-cle';
import RightDivider from '../../component/RightDivider';
import styled, { css } from 'styled-components';
import { ResponsiveContainer, IconButton, BulkActionsButton } from '../Button';
import HeaderRight, { HeaderContainer } from '../HeaderRight';
import axios, { CanceledError } from 'axios';
import { result, debounce } from 'lodash';
import { Helmet } from 'react-helmet';
import { t } from 'i18n';
import { TargetTextInput, TargetSelect, TargetDatePicker, TargetDateRangePicker, TargetMultiPick, TargetRadioButtons, TargetNumberInput, TargetMultiButtons, TargetTimePicker, TargetTimeRangePicker, TargetWeekPicker, TargetMonthPicker } from '../Target';
import { Toggle } from '../../component/FloatingSidebar';
import { Scrollbars } from 'react-custom-scrollbars';
import MyFilters from '../../component/MyFilters';
import _ from 'lodash';
import {severityTransformer} from "./RowTransformers";
import Dashboard from './Overview/Dashboard';
import Split from './Overview/Split';
import FullIcon from './Overview/icons/full.svg';
import HorizontalIcon from './Overview/icons/horizontal.svg';
import VerticalIcon from './Overview/icons/vertical.svg';

// Around the same breakpoint as the table content gets aligned vertically
const sidebarBreakpoint = 768

const SPLIT_DASHBOARD_OPTIONS = [
    { value: null, text: <img src={FullIcon} alt="full" /> },
    { value: 'horizontal', text: <img src={HorizontalIcon} alt="full" /> },
    { value: 'vertical', text: <img src={VerticalIcon} alt="full" /> },
];

const StyledContentContainer = styled(ContentContainer)`
    @media (max-width: ${sidebarBreakpoint}px) {
        flex-direction: column;
    }
`

const FullWidthContent = css`
    main {
        max-width: initial;
    }
`

const DashboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
    gap: 25px;
    flex: 1 1 auto;
    background-color: #eff2f5;
    border-bottom: 1px solid #DEDEDE;
    > ${HeaderContainer} {
        flex: 0 0 auto;
        margin-bottom: 0 !important;
    }
    > :last-child {
        min-height: 0;
        flex: 1 1 0;
    }
`;

const SplitTableContent = styled(Content)`
    main {
        padding: 0;
        max-width: initial;
        > .ui.table {
            border-radius: 0 !important;
            border: unset !important;
        }
    }
    border-radius: 0.28571429rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: 0 1px 3px 0 #d4d4d5;
`;

const CompactSpacedContent = css`
    main {
       padding: 8px;
    }
`
export const SidebarToggle = styled(Toggle)`
    > * {
        top: ${({ offset }) => offset};
        z-index: 75;
        ${({
    isActive,
    activeFgColor = '#FFF',
    activeBgColor = 'rgba(0, 0, 0, 0.6)',
    fgColor = '#FFF',
    bgColor = 'transparent',
}) => `
            color: ${isActive ? activeFgColor : fgColor};
            background-color: ${isActive ? activeBgColor : bgColor};
        `}
    }
`;

export const SidebarContent = styled.div`
    padding: 2rem;
    @media (max-width: ${sidebarBreakpoint}px) {
        & > form {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            & > div {
                width: 45%;
            }
        }
    }
`;

const PaginationContainer = styled.div`
    display: flex;
`;

const StyledMenu = styled(Menu)`
    margin-left: 10px !important;
`;

const StyledTargetRadioButtons = styled(TargetRadioButtons)`
    margin-right: 1rem;
    ${({ icon }) => icon && `
        > .icon.buttons > .ui.button > img {
            width: 1em;
            height: 1em;
            margin: calc((0.85714286em - 1em) / 2);
            opacity: 0.54;
            transition: opacity 0.1s ease;
        }
        > .icon.buttons > .ui.button.active > img,
        > .icon.buttons > .ui.button:hover > img {
            opacity: 0.845;
        }
    `}
`;

const TARGETS = {
    text: TargetTextInput,
    date: TargetDatePicker,
    select: TargetSelect,
    dateRange: TargetDateRangePicker,
    multiPick: TargetMultiPick,
    search: TargetTextInput,
    radioButtons: TargetRadioButtons,
    multiButtons: TargetMultiButtons,
    bool: TargetRadioButtons,
    number: TargetNumberInput,
    time: TargetTimePicker,
    timeRange: TargetTimeRangePicker,
    week: TargetWeekPicker,
    month: TargetMonthPicker,
};
const DEBOUNCE = {
    text: true,
    search: true,
    number: true,
};
const DEFAULT_PROPS = {
    dateRange: {
        startPlaceholder: t('form.startDate'),
        endPlaceholder: t('form.endDate'),
    },
    timeRange: {
        startPlaceholder: t('form.startTime'),
        endPlaceholder: t('form.endTime'),
    },
    search: {
        label: t('form.search'),
        name: 'search',
    },
    bool: {
        options: BOOL_OPTIONS,
        clearable: true,
    },
    radioButtons: {
        clearable: true,
    },
    select: {
        skipFetch: true,
    },
};

const SMALL_STYLE_FIRST = {
    paddingRight: 2,
    textAlign: 'center',
};

const SMALL_STYLE = {
    paddingLeft: 2,
    paddingRight: 2,
    textAlign: 'center',
};

const SMALL_STYLE_LAST = {
    paddingLeft: 2,
    paddingRight: 2,
    textAlign: 'center',
};

const SCROLLBAR_STYLE = {
    cursor: 'pointer',
    borderRadius: 'inherit',
    backgroundColor: 'rgba(0,0,0,.2)',
    zIndex: 1,
    position: 'relative',
    display: 'block',
    width: '100%'
}

const ModalContentContainer = styled(ContentContainer)`
    position: relative;
    height: 100%;
    ${SidebarToggle} > * {
        z-index: 1000;
        top: 0px;
        right: ${({ index }) => index * 3 + 1}rem;
        background-color: ${({ isActive }) => isActive ? '#e0e0e0' : '#f8f8f8'} !important;
    }
    ${SidebarToggle} > i.icon {
        margin: 0 !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 0.9rem !important;
        border-bottom-right-radius: 0.9rem !important;
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-top-width: 0;
        color: rgba(0, 0, 0, 0.87) !important;
    }
`;

// Copy pasta from re-cy-cle because the re-cy-cle Sidebar does not work with
// styled-components and these are not exported
const StyledAside = styled.aside`
    ${({ show, medium, small, theme }) => {
        const width = medium ? 450 : small ? 300 : 350;
        return `
            flex: 0 0 auto;
            width: ${width}px;
            background: ${theme.lightColor};
            ${show ? `` : `
                margin-right: -${width}px;
            `}
            transition: margin-right 300ms ease;
        `;
    }};
    display: ${({ show }) => show ? 'flex' : 'none'};
    flex-direction: column;

    @media (max-width: ${sidebarBreakpoint}px) {
        ${({ show, medium, small, theme }) => {
        const height = medium ? 350 : small ? 200 : 250;
        return `
            flex: 0 0 auto;
            width: 100%;
            height: ${height}px;
            background: ${theme.lightColor};
            ${show ? `` : `
                margin-bottom: -${height}px;
            `}
            transition: margin-bottom 300ms ease;
            z-index: 0;
        `;
    }};
    }

    ${({ borderBottom }) => borderBottom && `
        border-bottom: 1px solid #DEDEDE;
    `}
`;

const SidebarFilters = styled.div`
    flex: 1 1 auto;
`;

const SidebarStats = styled.div`
    background-color: #E8E8E8;
    flex: 0 0 auto;
    padding: 1rem;
`;

const SidebarStat = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 1.25em;

    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    &:last-child {
        border-bottom: none;
    }
`;

const SidebarStatLabel = styled.div`
    flex: 0 0 auto;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.4);
`;

const SidebarStatValue = styled.div`
    flex: 1 1 auto;
    text-align: right;
`;

const SidebarStatLoader = styled(Loader)`
    margin: -0.5em 0 !important;
    width: 1em !important;
    height: 1em !important;
    &::before, &::after {
        width: 1em !important;
        height: 1em !important;
        margin: 0 0 0 -0.5em !important;
    }
`;

const SmallFormGroup = styled(Form.Group)`
    > .field {
        min-width: 0 !important;
    }
`;

export const StyledCheckbox = styled(Checkbox)`
    margin: -0.5rem 0;
    vertical-align: middle !important;

    label {
        background: white !important;
        border: 2px solid #757575;
        border-radius: 2px;
        cursor: pointer;
        height: 18px;
        width: 18px;
    }

    label:before {
        display: none;
    }

    label:after {
        border-radius: 2px;
        top: -1px !important;
        left: -1px !important;
        width: 16px !important;
        height: 16px !important;

        background: #757575;
        color: white !important;
    }
`

const StyledTable = styled(Table)`
    &.ui[class*="very compact"].table {
        td {
            padding: 4px 8px;
        }
        th {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
    border-color: #DEDEDE !important;
    background-color: #F0F0F0 !important;
`;

const LoadingContent = styled((props) => <Progress active percent={100} {...props} />)`
    position: absolute !important;
    left: 0.78571429em;
    top: calc(50% - 1.4285em * 0.5);
    width: calc(100% - 0.78571429em * 2);
    height: 1.4285em !important;
    margin: 0 !important;
    > .bar {
        height: 1.4285em !important;
        background-color: #F0F0F0 !important;
    }
`;

export class Sidebar extends Component {
    static propTypes = {
        children: PropTypes.node,
        medium: PropTypes.bool,
        small: PropTypes.bool,
        show: PropTypes.bool,
        stats: PropTypes.node,
        wrap: PropTypes.bool,
    };

    static defaultProps = {
        show: true,
        wrap: true,
    };

    render() {
        const { children, stats, wrap, ...props } = this.props;

        let content = children;

        if (wrap) {
            content = (
                <Scrollbars>
                    <SidebarContent>{content}</SidebarContent>
                </Scrollbars>
            );
        }

        if (stats) {
            content = (
                <React.Fragment>
                    <SidebarFilters>{content}</SidebarFilters>
                    <SidebarStats>{stats}</SidebarStats>
                </React.Fragment>
            );
        }

        return (
            <StyledAside {...props}>{content}</StyledAside>
        );
    }
}

export const StyledTableHeaderCell = styled(Table.HeaderCell)`
    ${({ onClick }) => onClick ? `
        cursor: pointer !important;
        user-select: none;
    ` : ``}
    white-space: nowrap;
    text-align: ${({ textAlign = 'left' }) => textAlign} !important;
    position: sticky;
    top: 0;
    z-index: 1;
`;

export const SortIconGroup = styled(Icon.Group)`
    margin-right: 0.5rem;
`;

export const SortIcon = styled(Icon)`
    opacity: ${({ active }) => active ? 0.9 : 0.2} !important;
    margin-bottom: 0.1rem !important;
`;

export function getModelName(model) {
    return snakeToCamel(model.backendResourceName.replace(/\//g, '_'));
}

const StyledButton = styled(Button)`
    margin: -0.5rem 0.25rem -0.5rem 0 !important;
    &:last-child {
        margin-right: 0 !important;
    }
`;

const StyledSpan = styled.span`
    position: relative;
    margin-right: 0.25rem;
    &:last-child {
        margin-right: 0;
    }
`;

export const ItemButton = ({ icon, label, ...props }) => {
    const key = props['key'];
    delete props['key'];

    const button = (
        <StyledButton primary size="small" icon={icon} {...props} />
    );

    if (label) {
        return (
            <Popup key={key} content={label} trigger={<StyledSpan>{button}</StyledSpan>} position='top right'/>
        );
    }

    return button;
};

// TODO: Rename to TableRow? Need to find projects which actually use this, and
// change those as well.
export const StyledTableRow = styled(Table.Row)`
    background-color: #FFF;
    ${props => props.deleted && `
        opacity: 0.5;
    `}

    ${props => props.selected && `
        background: #e0e0e0;
    `}

    ${props => props.background && `
        background: ${props.background}
    `}

    ${props => props.indicator && `
        > td:first-child {
            border-left: 4px solid ${props.indicator} !important;
        }
    `}
`;

const primaryColor = (props) => props.theme.primaryColor;

const expandBase = css`
    content: '';
    position: absolute;
    pointer-events: none;
    z-index: 1;

    border-width: 1px;
    border-bottom-width: 3px;
    border-style: solid;
    border-color: ${primaryColor} ${primaryColor} #FFF;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
`;

export const StyledTableCell = styled(Table.Cell)`
    ${({ expandEnabled }) => expandEnabled && 'cursor: pointer;'}
    ${({ expandToggled }) => expandToggled && css`
        position: relative;
        &:before {
            ${expandBase};
            left: 0;
            width: 100%;
            top: 4px;
            height: calc(100% - 2px);
        }
    `}
`;

const ExpandRow = styled(StyledTableRow)`
    > td {
        border: 1px solid ${primaryColor} !important;
        border-radius: 4px !important;
        ${({ first }) => first && 'border-top-left-radius: 0 !important;'}
        ${({ last }) => last && 'border-top-right-radius: 0 !important;'}
        ${({ noPadding }) => noPadding && 'padding: 0 !important;'}
    }
`

const ItemButtonExpandedOverlay = styled.div`
    ${expandBase};
    left: -3px;
    width: calc(100% + 6px);
    ${({ height }) => `
        bottom: calc(50% - ${height / 2 + 2}px);
        height: calc(0.78571429em + 0.5em + ${height / 2 + 4}px);
    `};
`;

@observer
class ExpandItemButton extends Component {
    static propTypes = {
        active: PropTypes.bool.isRequired,
    };

    constructor(...args) {
        super(...args);
        this.buttonRef = this.buttonRef.bind(this);
    }

    @observable height = null;

    resizeObserver = new ResizeObserver(([{ target }]) => this.height = target.clientHeight);

    buttonRef(node) {
        if (node === null) {
            this.resizeObserver.disconnect();
            return;
        }

        node = ReactDOM.findDOMNode(node);
        while (node.tagName !== 'TD') {
            node = node.parentNode;
        }

        this.resizeObserver.observe(node);
        console.log(this.height);
        this.height = node.clientHeight;
    }

    render() {
        const { active, ...props } = this.props;
        return (
            <StyledSpan>
                {active && this.height !== null && <ItemButtonExpandedOverlay height={this.height} />}
                <ItemButton innerRef={this.buttonRef} active={active} {...props} />
            </StyledSpan>
        );
    }
}

export const ToolbarButton = ({ ...props }) => (
    <Button primary compact labelPosition="left" {...props} />
);

const PageInput = styled(Input)`
    width: 80px;
    margin-right: 5px;
`;

export const FullDimmable = styled(Dimmer.Dimmable)`
    width: 100%;
    height: 100%;
    flex: 1;
    > .ui.dimmer {
        pointer-events: none;
    }
`;

@observer
export class LoadingAnimation extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
    };

    render() {
        return (
            <Dimmer data-test-loading inverted active={this.props.store.isLoading}>
                <Loader inverted size="big" />
            </Dimmer>
        );
    }
}

@observer
export class PaginationControls extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        showLimit: PropTypes.bool,
        onFetch: PropTypes.func,
        afterFetch: PropTypes.func,
    };

    @observable
    newPage = 1;

    handleNewPageOpen() {
        this.newPage = this.store.currentPage;
    }

    handleNewPageChange(e, { value }) {
        this.newPage = parseInt(value);
    }

    handleNewPageApply() {
        const { afterFetch, store } = this.props;

        // If a page is entered higher than amount of pages there are go to the last page
        if (this.newPage > store.totalPages) {
            this.newPage = store.totalPages;
        }

        this.onFetch(this.props.store.setPage(this.newPage)).then(afterFetch);
    }

    handleChangeLimit(newLimit) {
        const { store, setLocalStorage } = this.props;
        store.setLimit(newLimit);
        setLocalStorage(newLimit); //set store limit

        this.handleNewPageApply();
    }

    onFetch(p) {
        const { onFetch } = this.props;

        onFetch && onFetch(p);

        return p;
    }

    renderPaginationLimit() {
        const { showLimit, value} = this.props;

        if (!showLimit) {
            return null;
        }

        const paginationOptions = [
            { key: 1, text: '10', value: 10 },
            { key: 2, text: '25', value: 25 },
            { key: 3, text: '50', value: 50 },
            { key: 4, text: '100', value: 100 },
            { key: 5, text: '500', value: 500 },
            { key: 6, text: '1000', value: 1000 },
        ];

        const pageLimitDropdown = (
            <Dropdown
                upward
                options={paginationOptions}
                onChange={(e, data ) => this.handleChangeLimit(data.value)}
                value={value}
                data-test-pagination-limit
            />
        );

        return (
            <StyledMenu pagination size="mini" borderless>
                <Menu.Item
                >
                    {t('common.pagination.limit')}
                </Menu.Item>
                <Menu.Item
                >
                    {pageLimitDropdown}
                </Menu.Item>
            </StyledMenu>
        )
    }

    render() {
        const { store, afterFetch } = this.props;

        const currentPage = (
            <Menu.Item as="a">
                {store.currentPage}/{store.totalPages}
            </Menu.Item>
        );

        const currentPageWithPopUp = (
            <Popup
                hoverable
                trigger={currentPage}
            >
                <Popup.Content>
                <Form onSubmit={this.handleNewPageApply.bind(this)}>
                    <PageInput
                        size="mini"
                        value={this.newPage || ''}
                        onChange={this.handleNewPageChange.bind(this)}
                    />
                    <Button primary size="mini" icon="arrow right" type='submit' />
                </Form>
                </Popup.Content>
            </Popup>
        );



        return (
            <PaginationContainer>
                <Menu pagination size="mini">

                <Menu.Item icon
                    as="a"
                    onClick= {store.hasPreviousPage ? ( () => this.onFetch(store.getPreviousPage()).then(afterFetch) ) : undefined}
                    disabled={!store.hasPreviousPage}
                >

                        <Icon name="chevron left" />
                    </Menu.Item>

                    {currentPageWithPopUp}

                <Menu.Item icon
                    as="a"
                    onClick={store.hasNextPage ? ( () => this.onFetch(store.getNextPage()).then(afterFetch) ) : undefined}
                    disabled={!store.hasNextPage}
                >
                    <Icon name="chevron right" />
                </Menu.Item>

                </Menu>
                {this.renderPaginationLimit()}

            </PaginationContainer>
        );
    }
}

@observer
export class TableHeader extends Component {
    static propTypes = {
        overview: PropTypes.object.isRequired,
        store: PropTypes.instanceOf(Store).isRequired,
        setting: PropTypes.object.isRequired,
    };

    constructor(...args) {
        super(...args);
        this.onSort = this.onSort.bind(this);
    }

    getOrderBy() {
        const { store } = this.props;
        if (store.params.order_by) {
            return store.params.order_by.split(',');
        } else {
            return [];
        }
    }

    setOrderBy(value) {
        const { store } = this.props;

        if (value.length === 0) {
            remove(store.params, 'order_by');
        } else {
            set(store.params, 'order_by', value.join(','));
        }
    }

    @computed get orderBy() {
        return this.getOrderBy();
    }

    set orderBy(value) {
        return this.setOrderBy(value);
    }

    @computed get sortKey() {
        const { setting } = this.props;
        let { sortKey } = setting;

        if (typeof sortKey === 'function') {
            sortKey = sortKey();
        }
        if (typeof sortKey === 'string') {
            sortKey = [sortKey];
        }

        return sortKey;
    }

    sortState() {
        if (!this.sortKey) {
            return null;
        }

        if (this.getOrderBy().includes(this.sortKey[0])) {
            return 'asc';
        } else if (this.getOrderBy().includes(`-${this.sortKey[0]}`)) {
            return 'desc';
        } else {
            return null;
        }
    }

    onSort() {
        const { store } = this.props;

        switch (this.sortState()) {
            case null: // To 'asc'
                this.setOrderBy([...this.sortKey, ...this.orderBy]);
                break;
            case 'asc': // To 'desc'
                this.setOrderBy([
                    ...this.sortKey.map((key) => `-${key}`),
                    ...this.getOrderBy().filter((key) => !this.sortKey.includes(key)),
                ]);
                break;
            case 'desc': // To null
                const descSortKeys = this.sortKey.map((key) => `-${key}`);
                this.setOrderBy(this.getOrderBy().filter((key) => !descSortKeys.includes(key)));
                break;
            default:
                throw new Error('Invalid sortState');
        }

        if (store.updateUrlParams) {
            store.updateUrlParams();
        }

        this.forceUpdate();
        this.fetch();
    }

    render() {
        const { setting } = this.props;
        const { label, collapsing, props } = setting;

        return (
            <StyledTableHeaderCell
                data-test-sort={this.sortKey ? this.sortKey : undefined}
                onClick={this.sortKey ? this.onSort : undefined}
                collapsing={collapsing}
                {...props}
            >
                {this.sortKey && (
                    <SortIconGroup>
                        {/* First Icon is positioned weirdly for some reason so dummy icon */}
                        <Icon />
                        <SortIcon
                            name="sort ascending"
                            active={this.sortState() === 'asc'}
                        />
                        <SortIcon
                            name="sort descending"
                            active={this.sortState() === 'desc'}
                        />
                    </SortIconGroup>
                )}
                {label}
            </StyledTableHeaderCell>
        );
    }

    fetch() {
        const { overview } = this.props;
        return overview.fetch();
    }

    debouncedFetch = debounce(this.fetch, ACTION_DELAY);
}


@observer
export default class AdminOverview extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        viewStore: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object,
        location: PropTypes.object,
    };

    constructor(...args) {
        super(...args);
        this.contentRef = this.contentRef.bind(this);
        this.lazyLoadingRowRef = this.lazyLoadingRowRef.bind(this);
        this.checkLazyLoading = this.checkLazyLoading.bind(this);
        this.startReorder = this.startReorder.bind(this);
        this.moveReorder = this.moveReorder.bind(this);
        this.stopReorder = this.stopReorder.bind(this);
    }

    TableHeader = TableHeader;
    Content = Content;

    @computed get finalDashboards() {
        let dashboards;
        if (this.dashboards) {
            dashboards = this.dashboards;
        } else if (this.dashboard) {
            dashboards = [{ icon: 'dashboard', ...this.dashboard }];
        } else {
            dashboards = [];
        }
        return dashboards.filter(({ show = true }) => {
            if (typeof show === 'function') {
                show = show();
            }
            return show;
        });
    }

    @observable baseShowDashboard = undefined;
    defaultShowDashboard = null;

    @computed get showDashboard() {
        if (this.finalDashboards.length === 0) {
            return null;
        }
        if (this.baseShowDashboard !== undefined) {
            return this.baseShowDashboard;
        }
        let showDashboard = this.myFilterKey ? localStorage.getItem(`show-dashboard-${this.myFilterKey}`) : null;
        if (showDashboard === null) {
            return this.defaultShowDashboard;
        } else {
            showDashboard = JSON.parse(showDashboard);
            if (showDashboard !== null && !this.finalDashboards[showDashboard]) {
                localStorage.removeItem(`show-dashboard-${this.myFilterKey}`);
                return this.defaultShowDashboard;
            }
            return showDashboard;
        }
    }

    set showDashboard(show) {
        this.baseShowDashboard = show;
        this.baseSplitDashboard = undefined;
        if (this.myFilterKey) {
            localStorage.setItem(`show-dashboard-${this.myFilterKey}`, JSON.stringify(show));
        }
        this.fetch();
    }

    @computed get showDashboardOptions() {
        const options = this.finalDashboards.map(({ icon }, value) => ({ value, icon }));
        options.push({ value: null, icon: 'table' });
        return options;
    }

    defaultSplitDashboard = null;
    @observable baseSplitDashboard = undefined;

    @computed get splitDashboard() {
        if (this.baseSplitDashboard !== undefined) {
            return this.baseSplitDashboard;
        }
        let splitDashboard = this.myFilterKey ? localStorage.getItem(`split-dashboard-${this.myFilterKey}-${this.showDashboard}`) : null;
        if (splitDashboard === null) {
            return this.defaultSplitDashboard;
        } else {
            return JSON.parse(splitDashboard);
        }
    }

    set splitDashboard(split) {
        const shouldFetch = (split === null) !== (this.splitDashboard === null);
        this.baseSplitDashboard = split;
        if (this.myFilterKey) {
            localStorage.setItem(`split-dashboard-${this.myFilterKey}-${this.showDashboard}`, JSON.stringify(split));
        }
        if (shouldFetch) {
            this.fetch();
        }
    }

    get showTable() {
        return this.showDashboard === null || this.splitDashboard !== null;
    }

    getDashboardStats() {
        if (this.showDashboard === null) {
            return [];
        }
        const dashboard = this.finalDashboards[this.showDashboard];
        return dashboard.stats.map(({ stat }) => stat);
    }

    @computed get dashboardStats() {
        return this.getDashboardStats();
    }

    @observable dashboardLoading = 0;
    @observable dashboardData = null;

    /**
     * You can override how the default table row looks like by setting this variable. Example:
     *
     * import { StyledTableRow } from 'spider/semantic-ui/Admin/Overview';
     *
     * const SpecialTableRow = styled(StyledTableRow)`
     *     background-color: red;
     * `;
     *
     * class ProgressOverviewScreen extends AdminOverview {
     *     TableRow = SpecialTableRow;
     * }
     */
    Table = StyledTable;
    TableRow = StyledTableRow;
    TableCell = StyledTableCell;
    ExpandRow = ExpandRow;
    Toolbar = Toolbar;

    DATE_FORMAT = 'DD-MM-YYYY';

    header = '';
    HeaderRight = HeaderRight
    title = '';
    tabTitlePrefix = null;

    myFilterKey = null;
    myFilterBlacklist = []
    myFilterWhitelist = undefined;
    myFilterProps = {};

    /**
     * If an EditModal component is set (this is expected to inherit from
     * spider/semantic-ui/Admin/Edit/Modal) this will be used instead of urls
     * for the 'edit'-type in buttons and the 'add'-type in the toolbar.
     */
    EditModal = null;

    /**
     * If an EditScreen component is set (this is expected to inherit from
     * spider/semantic-ui/Admin/Edit/Screen) this will be used instead of urls
     * for the 'edit'-type in buttons and the 'add'-type in the toolbar.
     */
    EditScreen = null;

    /**
     * Sync url with store params, so when refreshing, the store will recieve
     * the same params and remembers filter settings.
     */
    bindUrlParams = true;

    /**
     * When turned on checkboxes are added and items can be selected. Selection can then
     * be retrieved using getSelection()
     */
    multiSelection = false;
    reorderable = false;

    @observable selectionStore = new Store();

    lazyLoading = false;

    @observable lazyLoadingEnd = false;

    allowNewlines = false;

    RENDER_TYPE_COMPACT = 'compact';
    RENDER_TYPE_RELAXED = 'relaxed';

    RENDER_TYPE_OPTIONS = [
        {
            text: t('common.overviewRender.type.relaxed'),
            value: this.RENDER_TYPE_RELAXED,
        },
        {
            text: t('common.overviewRender.type.compact'),
            value: this.RENDER_TYPE_COMPACT,
        },
    ]
    getSelection() {
        return this.selectionStore.models;
    }

    toggleItemSelection(item, selected) {
        if (selected) {
            this.selectionStore.models.push(item);
        } else {
            this.selectionStore.removeById(item.id);
        }

    }

    isItemSelected(item) {
        return !!this.selectionStore.get(item.id);
    }

    toggleAllSelection(selected) {
        // in a lazy loading setting the user will expect a select all to also select all
        // the items that have not been lazy loaded yet, thus we just load everything first
        if (selected && this.lazyLoading && !this.lazyLoadingEnd) {
            return this.lazyLoad({ all: true }).then(() => this.toggleAllSelection(true));
        }

        if (selected) {
            this.selectionStore.models = _.uniqBy(this.selectionStore.models.concat(this.store.models), (m) => m.id);
        } else {
            this.selectionStore.models = [];
        }
    }

    isAllSelected() {
        return this.store.models.every((m) => this.selectionStore.models.map(m => m.id).includes(m.id)) && this.store.models.length > 0;
    }

    isAnySelected() {
        return this.selectionStore.models.length > 0;
    }

    @observable bulkActions = [];
    @observable expandedCells = {};
    /**
     * Flag to ensure only one expand is open at a time.
     */
    singleExpand = false;

    /**
     * Flag to enable highlighting rows on hover
     */
    highlightedRows = false;
    /**
     * Table support multiple rendering option types. See RENDER_TYPE_OPTIONS
     */
    showRenderTypeToggle = false;
    @observable selectedRenderType = this.RENDER_TYPE_RELAXED;

    /**
     * Directly fetch store when mounted. Disable for performance. Can be a
     * function.
     *
     */
    fetchOnMount() {
        return !this.myFilterKey;
    }

    /**
     * Sometimes you want to show an initial message, before the first fetch occures.
     */
    @observable initialFetchOccured = false;

    /**
    * If set to true next to pagination controls limit dropdown will be shown
    */
    showPaginationLimit = false;
    @observable paginationLimit = 25;

    defaultShowSidebar = true;

    @observable meta = {};

    /**
     * Add buttons per row. Example:
     * buttons: [
     *    (model) => <Button>{model.id}</Button>
     * ];
     */
    itemButtonProps = {};
    buttons = [];
    toolbar = [];

    /**
     * Render (multiple) sidebars. Only 1 sidebar can be active at the same time.
     * Example:
     *
     * sidebars = [
     * {
     *     trigger: props => <IconButton name="search" {...props} />,
     *     content: () => (
     *         <Form>
     *             {this.finalFilters.map(this.renderFilter.bind(this))}
     *         </Form>
     *     )
     * }, {
     *     trigger: props => <IconButton name="search" {...props} />,
     *     content: () => (
     *         <Form>
     *             {this.finalFilters.map(this.renderFilter.bind(this))}
     *         </Form>
     *     )
     * }];
     */

    defaultSidebars() {
        return [
            {
                trigger: this.renderSidebarTrigger.bind(this),
                content: this.renderSidebarContent.bind(this)
            }
        ]
    }

    /**
     * Sidebars to render
     * could be either an array or a function so you could make use of defaultSidebars method
     */
    sidebars = this.defaultSidebars();

    @computed get finalSidebars() {
        let sidebars;
        if (typeof this.sidebars == "function") {
            sidebars = this.sidebars();
        } else {
            sidebars = this.sidebars;
        }
        return sidebars;
    }

    @observable baseSidebarActiveIndex = undefined;

    @computed get sidebarActiveIndex() {
        if (this.baseSidebarActiveIndex !== undefined) {
            return this.baseSidebarActiveIndex;
        }

        let showSidebar = this.myFilterKey ? localStorage.getItem(`show-sidebar-${this.myFilterKey}`) : null;
        if (showSidebar === null) {
            return this.defaultShowSidebar && this.finalSidebars.length > 0 ? 0 : null;
        }

        showSidebar = JSON.parse(showSidebar);
        if (showSidebar !== null) {
            if (this.finalSidebars.length === 0) {
                showSidebar = null;
            } else if (showSidebar < 0) {
                showSidebar = 0;
            } else if (showSidebar >= this.finalSidebars.length) {
                showSidebar = this.finalSidebars.length - 1;
            }
        }
        return showSidebar;
    }

    set sidebarActiveIndex(index) {
        this.baseSidebarActiveIndex = index;
        if (this.myFilterKey) {
            localStorage.setItem(`show-sidebar-${this.myFilterKey}`, JSON.stringify(this.baseSidebarActiveIndex));
        }
    }

    sidebarsToggleTopOffset = '53px';

    /**
     * Render (multiple) filters. Example:
     *
     * filters = [
     *     { type: 'text', name: '.name:icontains', label: t('driver.field.name.label') },
     * ]
     */
    // filters = [];

    @computed get next() {
        if (this.props.location) {
            return encodeUrlByPathname(this.store, this.props.location.pathname);
        }

        return null;
    }

    filterShow(values) {
        const filtered = [];
        for (const value of values) {
            if (typeof value === 'function') {
                filtered.push(value);
                continue;
            }
            let { show = true, ...rest } = value;
            if (typeof show === 'function') {
                show = show();
            }
            if (show) {
                filtered.push(rest);
            }
        }
        return filtered;
    }

    @computed get finalButtons() {
        let buttons = this.getButtons();

        if (typeof buttons === 'function') {
            buttons = buttons();
        }

        if (!buttons) {
            buttons = [];
        }

        return this.filterShow(buttons);
    }

    @computed get finalFilters() {
        let filters = this.getFilters();

        if (typeof filters === 'function') {
            filters = filters();
        }

        if (filters === true) {
            return true;
        }

        if (!filters) {
            filters = [];
        }

        return this.filterShow(filters);
    }

    @computed get ComposedContent() {
        // Set content rendering style.
        if (this.showDashboard !== null) {
            return SplitTableContent;
        }
        if (this.fullWidth) {
            return styled(this.Content)`
                ${FullWidthContent}
            `;
        }
        if (this.selectedRenderType === this.RENDER_TYPE_COMPACT) {
            return styled(this.Content)`
                ${CompactSpacedContent}
            `;
        }
        return this.Content;
    }

    componentDidMount() {
        if (this.bindUrlParams) {
            this.clearUrlBinding = bindUrlParams({
                store: this.store,
                defaultParams: this.getDefaultParams(),
            });
        }

        if (result(this, 'fetchOnMount')) {
            this.fetch();
        }

        this.configureStyling();

        document.addEventListener('mousemove', this.moveReorder);
        document.addEventListener('mouseup', this.stopReorder);
        this.setStoreLimit();
    }

    configureStyling() {
        this.selectedRenderType = JSON.parse(localStorage.getItem(`render-type-${this.myFilterKey}`))
        // this.itemButtonProps = { size: this.selectedRenderType === this.RENDER_TYPE_COMPACT ? 'mini' : 'small' };
    }

    setStoreLimit(){
        if(JSON.parse(localStorage.getItem(`pagination-limit-${this.myFilterKey}`))){
            this.paginationLimit = JSON.parse(localStorage.getItem(`pagination-limit-${this.myFilterKey}`));
            this.store.setLimit(this.paginationLimit);
        }
    }

    setDimmerMargin() {
        console.log('DIMMER MARGIN');
    }

    componentWillUnmount() {
        if (this.clearUrlBinding) {
            this.clearUrlBinding();
        }

        document.removeEventListener('mousemove', this.moveReorder);
        document.removeEventListener('mouseup', this.stopReorder);
    }

    debouncedFetch = debounce(this.fetch.bind(this), ACTION_DELAY);
    async fetch() {
        this.initialFetchOccured = true;

        if (this.cancelRequest) {
            this.cancelRequest();
        }

        const cancelToken = new axios.CancelToken(c => this.cancelRequest = c);
        const promises = [];
        if (this.dashboardStats.length !== 0) {
            promises.push(this.fetchDashboardData(cancelToken));
        } else {
            this.dashboardData = null;
        }
        if (this.showTable) {
            promises.push(this.fetchTableData(cancelToken));
        } else {
            this.store.clear();
        }
        try {
            return await Promise.all(promises);
        } catch (e) {
            if (e instanceof CanceledError) {
                // Ignore canceled errors.
                return;
            }

            throw e;
        } finally {
            delete this.cancelRequest;
        }
    }

    async fetchTableData(cancelToken) {
        const response = await this.store.fetch({ cancelToken });
        runInAction(() => {
            this.lazyLoadingEnd = (
                this.store.__state.limit === null ||
                this.store.__state.limit > this.store.models.length
            );
            set(this.meta, response.meta);
            this.afterFetch(response);
        });
    }

    async fetchDashboardData(cancelToken) {
        this.dashboardLoading++;
        try {
            this.dashboardData = await this.store.api.get(
                `${this.store.url()}stats/`,
                {
                    ..._.omit(this.store.params, ['order_by']),
                    stats: this.dashboardStats.join(','),
                },
                { cancelToken },
            );
        } finally {
            this.dashboardLoading--;
        }
    }

    afterFetch() { }

    getDefaultParams() {
        return this.params;
    }

    /**
     * Backend handles deleted differently than other filters.
     */
    handleDeletedChange = (name, value) => {
        const store = this.store;

        if (value === 'true') {
            store.params[name] = 'true';
        } else {
            delete store.params[name];
        }

        // Mobx only supports already existing keys when you started @observable.
        // In this case we add / delete a key, so mobx can't properly observe
        // changes. In v4 of mobx this is "fixed" by using set / remove from
        // mobx, but we are alas still stuck in 3.1.2...
        this.forceUpdate();
        store.updateUrlParams();
        store.setPage().then(response => {
            set(this.meta, response.meta);
        });
    }

    getSettings() {
        return this.settings;
    }

    getButtons() {
        return this.buttons;
    }

    getFilters() {
        return this.filters;
    }

    getToolbar() {
        return this.toolbar;
    }

    getBulkActions() {
        return this.bulkActions;
    }

    @computed get mappedSettings() {
        return this.filterSettings(this.mapSettings(this.getSettings()));
    }

    attrSetting(setting) {
        const path = setting.attr.split('.');
        const field = path.pop();

        let model = this.store.Model;
        let label, sortKey;
        if (path.length > 0) {
            let prevModel = null;
            sortKey = '';
            let subPath = '';
            for (const field of path) {
                if (sortKey !== '') {
                    sortKey += '.';
                }
                if (subPath !== '') {
                    subPath += '.';
                }
                sortKey += camelToSnake(field);
                subPath += field;
                prevModel = model;
                model = new model().relations()[field];
                if (model === undefined) {
                    throw new Error(`Not an existing relation: ${subPath}`);
                }
            }
            label = t(`${getModelName(prevModel)}.field.${path[path.length - 1]}.label`);
            sortKey += '.' + camelToSnake(field);

            const rel = path.join('.');
            if (!this.store.__activeRelations.some((activeRel) => (
                activeRel === rel || activeRel.startsWith(`${rel}.`)
            ))) {
                throw new Error(`Not an active relation: ${path.join('.')}`);
            }
        } else {
            label = t(`${getModelName(model)}.field.${field}.label`);
            sortKey = camelToSnake(field);
        }

        return {
            ...setting,
            label: setting.label || label,
            sortKey: setting.sortKey || sortKey,
            attr: (field !== 'id' || ((model.idPrefix === undefined || model.idPrefix === '') && (model.idIcon === undefined || model.idIcon === ''))) ? (obj) => {
                for (const field of path) {
                    obj = obj[field];
                }
                return obj[field];
            } : (obj) => {
                for (const field of path) {
                    obj = obj[field];
                }
                return obj.id && obj.getLink();
            },
        };
    }

    handleSmall(setting, i) {
        if (setting.small) {
            return {
                ...setting,
                collapsing: true,
                props: {
                    style: (
                        i === 0
                            ? SMALL_STYLE_FIRST
                            : i === this.getSettings().length - 1
                                ? SMALL_STYLE_LAST
                                : SMALL_STYLE
                    )
                },
            };
        } else if (setting.centered) {
            return {
                ...setting,
                props: { style: { textAlign: 'center' } },
            };
        } else {
            return setting;
        }
    }

    /**
     * Check if the settings array has the attr or label properties filled in.
     */

    checkColumn(mappedSettings) {
        const lastElement = this.settings[this.settings.length - 1];

        // push column if buttons array is not empty(atleast one of attr or label is filled)
        // pop column from settings definition when buttons array is not defined or when
        // last settings element is empty
        if (this.buttons.length > 0 && lastElement
            && ('attr' in lastElement || 'label' in lastElement)) {
            mappedSettings.push({});
        } else if (this.buttons.length === 0 && lastElement === '') {
            mappedSettings.pop({});
        }

        return mappedSettings;
    }

    /**
     * Generate headings from settings. For now it auto creates labels based
     * on attr.
     */
    mapSettings(settings) {
        let mappedSettings = [];
        for (const rawSetting of settings) {
            let setting = typeof rawSetting === 'function' ? rawSetting() : rawSetting;
            // Auto add label if it's missings.
            if (typeof setting === 'string') {
                if (setting === '') {
                    setting = {};
                } else {
                    setting = { attr: setting };
                }
            }

            // if undefined or true - show, false - skip, function - call and show if result is true
            let show = setting.show ?? true;
            if (typeof show === 'function') {
                show = show();
            }
            if (!show) {
                continue;
            }

            if (
                typeof setting === 'object' &&
                typeof setting.attr === 'string'
            ) {
                setting = this.attrSetting(setting);
            }

            if (
                typeof setting === 'object' &&
                typeof setting.label === 'function'
            ) {
                setting.label = setting.label();
            }

            mappedSettings.push(this.handleSmall(setting));
        }

        // only add column if buttons are present or if setting has attr or label
        return this.checkColumn(mappedSettings);
    }

    filterSettings(settings) {
        const filtered = [];

        for (let { include = true, ...setting } of settings) {
            if (typeof include === 'function') {
                include = include();
            }
            if (include) {
                filtered.push(setting);
            }
        }

        return filtered;
    }

    generateSearchParams() {
        if (this.next) {
            return `?next=${this.next}`;
        }

        return '';
    }

    renderTitle() {
        return this.title && (
            <this.HeaderRight as="h1" content={this.title}>
                {this.renderTitleRight()}
            </this.HeaderRight>
        );
    }


    renderTitleRight() {
        return this.myFilterKey && (
            <MyFilters
                store={this.store}
                view={this.myFilterKey}
                blacklist={this.myFilterBlacklist}
                whitelist={this.myFilterWhitelist}
                defaultParams={this.getDefaultParams()}
                fromUrl={this.bindUrlParams}
                onFetch={this.fetch.bind(this)}
                {...this.myFilterProps}
            />
        );
    }

    renderTabTitle() {
        if (this.title && this.tabTitlePrefix) {
            return (
                <Helmet>
                    <title>{this.tabTitlePrefix}{this.title}</title>
                </Helmet>
            );
        }
    }

    renderContent() {
        return (
            <React.Fragment>
                {this.showDashboard !== null && (
                    <Helmet>
                        <style>{`
                            header > div:last-child > nav > .nav-item::before {
                                border-bottom-color: #eff2f5 !important;
                            }
                        `}</style>
                    </Helmet>
                )}
                {this.showDashboard === null ? (
                    this.renderFullTable(this.renderTitle())
                ) : !this.showTable ? (
                    <DashboardWrapper>
                        {this.renderTitle()}
                        {this.renderDashboard()}
                    </DashboardWrapper>
                ) : (
                    <DashboardWrapper>
                        {this.renderTitle()}
                        <Split
                            key={this.showDashboard}
                            direction={this.splitDashboard}
                            first={this.renderDashboard()}
                            second={this.renderFullTable()}
                            storagePrefix={this.myFilterKey ? `split-dashboard-${this.myFilterKey}-${this.showDashboard}-` : null}
                        />
                    </DashboardWrapper>
                )}
                {this.renderSidebars()}
            </React.Fragment>
        );
    }

    renderBody() {
        // Needed because calling super.render() will cause problems because of
        // how the @observer decorator changes the render method
        const content = this.renderContent();

        if (this.modal) {
            return (
                <ModalContentContainer>
                    {content}
                </ModalContentContainer>
            );
        }

        return (
            <>
                {this.renderTabTitle()}
                <StyledContentContainer>
                    {content}
                </StyledContentContainer>
                {this.renderToolbar.call(this)}
            </>
        );
    }

    render() {
        return this.renderBody();
    }

    renderDeletedFilter() {
        const params = this.store.params ? this.store.params : {};

        return (
            <Form.Field>
                <label>{t('common.filter.deleted')}</label>
                <RadioButtons
                    name="deleted"
                    onChange={this.handleDeletedChange}
                    value={params.deleted === 'true' ? 'true' : 'false'}
                    options={[
                        { value: 'false', label: t('form.no') },
                        { value: 'true', label: t('form.yes') },
                    ]}
                />
            </Form.Field>
        );
    }

    renderOverviewTable() {
        const compactClass = this.selectedRenderType === this.RENDER_TYPE_COMPACT ? 'small very compact' : '';

        return (
            <this.Table {...this.tableProps()} className={compactClass} selectable={this.highlightedRows}>
                <Table.Header>
                    {this.renderHeaderRow()}
                </Table.Header>
                <Table.Body>
                    {this.store.map(this.renderLazyRow.bind(this))}
                    {this.lazyLoading && !this.store.isLoading && (this.lazyLoadingEnd ? (
                        this.renderLazyLoadingEndRow()
                    ) : (
                        this.renderLazyLoadingRow()
                    ))}
                </Table.Body>
            </this.Table>
        );
    }

    renderHeaderRow() {
        return (
            <Table.Row>
                {this.multiSelection && (
                    <StyledTableHeaderCell>
                        <StyledCheckbox
                            data-test-overview-all-checkbox
                            onChange={() => this.toggleAllSelection(!this.isAnySelected())}
                            checked={this.isAllSelected()}
                            indeterminate={this.isAnySelected() && !this.isAllSelected()}
                        />
                    </StyledTableHeaderCell>
                )}
                {this.mappedSettings.map(this.renderHeader.bind(this))}
            </Table.Row>
        );
    }

    renderLazyLoadingRow() {
        const limit = Math.max(this.store.__state.limit, 1);
        const rows = [];

        for (let i = 0; i < limit; i++){
            const cells = [];
            while (cells.length < this.cells) {
                cells.push(<Table.Cell style={{ position: 'relative' }}><LoadingContent /></Table.Cell>);
            }
            let row = <this.TableRow key={i}>{cells}</this.TableRow>;
            if (i === 0) {
                row = <Ref key={i} innerRef={this.lazyLoadingRowRef}>{row}</Ref>;
            }
            rows.push(row);
        }

        return rows;
    }

    renderLazyLoadingEndRow() {
        return null;
    }

    contentNode = null;
    lazyLoadingRowNode = null;
    intersectionObserver = null;

    contentRef(node) {
        node = node && ReactDOM.findDOMNode(node);
        if (this.intersectionObserver !== null) {
            this.intersectionObserver.disconnect();
            this.intersectionObserver = null;
        }
        if (node !== null) {
            node = ReactDOM.findDOMNode(node);
            this.contentNode = node;
            this.intersectionObserver = new IntersectionObserver(this.checkLazyLoading, { root: node });
            if (this.lazyLoadingRowNode !== null) {
                this.intersectionObserver.observe(this.lazyLoadingRowNode);
                this.checkLazyLoading();
            }
        }
    }

    lazyLoadingRowRef(node) {
        this.lazyLoadingRowNode = node;
        if (this.intersectionObserver !== null) {
            this.intersectionObserver.disconnect();
            if (node !== null) {
                this.intersectionObserver.observe(this.lazyLoadingRowNode);
                this.checkLazyLoading();
            }
        }
    }

    checkLazyLoading() {
        const contentRect = this.contentNode.getBoundingClientRect();
        const lazyLoadingRowRect = this.lazyLoadingRowNode.getBoundingClientRect();

        if (lazyLoadingRowRect.top - 200 < contentRect.bottom) {
            this.lazyLoad();
        }
    }

    isLazyLoading = false;

    async lazyLoad({ all = false } = {}) {
        if (this.isLazyLoading) {
            return;
        }

        this.isLazyLoading = true;
        // We manage the pending request count manually here so that we can
        // guarantee it will get decreased after the new models are added,
        // this is so that the loader is still there when the frontend is
        // rendering the new rows, which for big tables can take significantly
        // more time than the API request itself.
        if (all) {
            this.store.__pendingRequestCount++;
        }

        try {
            const store = new this.store.constructor({
                relations: this.store.__activeRelations,
                params: {
                    ...this.store.params,
                    after: this.store.models[this.store.models.length - 1].id,
                    include_meta:'',
                },
                ...all ? { limit: false } : {},
            });

            if (this.cancelRequest) {
                this.cancelRequest();
            }

            const cancelToken = new axios.CancelToken(c => this.cancelRequest = c);
            await store.fetch({ cancelToken });
            delete this.cancelRequest;
            runInAction(() => {
                this.store.models.push(...store.models);
                if (all) {
                    this.lazyLoadingEnd = true;
                    this.store.__pendingRequestCount--;
                } else {
                    this.lazyLoadingEnd = store.models.length < store.__state.limit;
                }
            });
        } catch {
            this.lazyLoadingEnd = true;
            if (all) {
                this.store.__pendingRequestCount--;
            }
        } finally {
            this.isLazyLoading = false;
        }
    }

    renderDashboard() {
        return (
            <Dashboard
                store={this.store}
                fetch={this.dashboardFetch.bind(this)}
                split={this.splitDashboard}
                dashboard={this.finalDashboards[this.showDashboard]}
                data={this.dashboardData}
                loading={this.dashboardLoading > 0}
            />
        );
    }

    renderFullTable(title = null) {
        return (
            <FullDimmable>
                <LoadingAnimation store={this.store} />
                <this.ComposedContent ref={this.contentRef} renderThumbVertical={props => <div data-test-scrollbar={true} {...props} style={SCROLLBAR_STYLE} />}>
                    {title}
                    {this.renderOverviewTable()}
                </this.ComposedContent>
            </FullDimmable>
        );
    }

    @action dashboardFetch() {
        if (this.splitDashboard === null) {
            this.splitDashboard = 'horizontal';
        }
        this.fetch();
    }

    renderHeader(setting, i) {
        return (
            <this.TableHeader key={i} setting={setting} store={this.store} overview={this} />
        );
    }

    tableProps() {
        return {};
    }

    rowProps(item, i) {
        const props = {};

        if (this.reorderable) {
            props['data-reorderable'] = i;
        }

        return props;
    }

    cellProps(item, setting, i) {
        let props = (
            typeof setting.cellProps === 'function'
            ? setting.cellProps(item, i)
            : setting.cellProps !== undefined
            ? { ...setting.cellProps }
            : {}
        );

        if (!(setting.allowNewlines ?? this.allowNewlines ?? false)) {
            props.singleLine = true;
        }

        if (setting.small) {
            props.style = {
                ...(
                    i === 0
                        ? SMALL_STYLE_FIRST
                        : i === this.getSettings().length - 1
                            ? SMALL_STYLE_LAST
                            : SMALL_STYLE
                ),
                ...(props.style || {}),
            };
        }

        if (setting.centered) {
            props.style = {
                textAlign: 'center',
                ...(props.style || {}),
            };
        }

        if (setting.expand) {
            let canExpand = setting.canExpand ?? true;
            if (typeof canExpand === 'function') {
                canExpand = canExpand(item);
            }
            if (canExpand) {
                const toggled = this.expandedCells[item.cid] == i;
                props.expandEnabled = true;
                props.expandToggled = toggled;
                props.onClick = (e) => {
                    e.preventDefault();
                    this.toggleExpand(item.cid, i);
                };
            }
        }

        return props;
    }

    @action toggleExpand(cid, key) {
        if (this.expandedCells[cid] === key) {
            delete this.expandedCells[cid];
            return;
        }

        if (this.singleExpand) {
            for (const otherCid of Object.keys(this.expandedCells)) {
                delete this.expandedCells[otherCid];
            }
        }

        this.expandedCells[cid] = key;
    }

    /**
     * List of transformers for the row properties, which are applied in order
     *
     * Each transformer gets an object with the properties, and returns a object with the transformed properties
     *
     *
     * @type {({*}) => {*}}
     */
    rowPropertyTransformers = [
        severityTransformer
    ]

    /**
     * We want to make row props to be reusable, meaning that sometimes we have row properties which have multiple different
     * effects.
     *
     * An example of this is the severity for a row. Rather than setting a color, and a data-test-severity, we can set the severity as
     * property. E.g. {severity: error} can become {color: "#ff0000", data-test-severity: "error"}
     *
     * This can be used to make certain things reusable
     *
     * @param props
     * @returns {*}
     */
    transformRowProps(props) {
        let transformedProps = props;

        this.rowPropertyTransformers.forEach(transformer => {
            this.transformedProps = transformer(props);
        })

        return transformedProps;
    }

    getCells() {
        return !!this.multiSelection + this.mappedSettings.length;
    }

    @computed get cells() {
        return this.getCells();
    }

    currReorder = null;

    @action startReorder(e) {
        e.preventDefault();

        // Find the table & target row
        let table = e.target;
        let targetRow = null;
        while (table !== null && table.tagName !== 'TABLE') {
            if (table.tagName === 'TR') {
                targetRow = table;
            }
            table = table.parentNode;
        }
        if (table === null || targetRow === null) {
            return;
        }

        let source = targetRow.getAttribute('data-reorderable');
        source = source && parseInt(source);
        if (source === null || !this.canReorder(source)) {
            return;
        }

        this.currReorder = {
            table,
            source,
            xStart: e.clientX,
            yStart: e.clientY,
        };

        this.moveReorder(e);
    }

    *getReorderRows() {
        if (this.currReorder === null) {
            return null;
        }

        let nodes = null;

        for (const part of this.currReorder.table.children) {
            if (part.tagName !== 'THEAD' && part.tagName !== 'TBODY') {
                continue;
            }
            for (const row of part.children) {
                if (row.tagName !== 'TR') {
                    // nothing
                } else if (row.hasAttribute('data-reorderable')) {
                    if (nodes !== null) {
                        yield nodes;
                    }
                    nodes = [row];
                } else if (nodes !== null) {
                    nodes.push(row);
                }
            }
        }
        if (nodes !== null) {
            yield nodes;
        }
    }

    @action moveReorder(e) {
        if (this.currReorder === null) {
            return;
        }
        const { source, xStart, yStart } = this.currReorder;

        // Find all rows
        const rows = [];
        const heights = [];
        const cumHeights = [0];

        for (const row of this.getReorderRows()) {
            rows.push(row);
            const height = row.reduce((total, node) => total + node.clientHeight, 0);
            heights.push(height);
            const cumHeight = cumHeights[cumHeights.length - 1] + height;
            cumHeights.push(cumHeight);
        }

        // Calculate all target y coordinates
        let targetYs = [];
        for (let target = 0; target < heights.length; target++) {
            if (target <= source) {
                targetYs.push(cumHeights[target]);
            } else {
                targetYs.push(cumHeights[target + 1] - heights[source]);
            }
        }

        // Binary search for the nearest targets
        const dx = e.clientX - xStart;
        const dy = e.clientY - yStart;
        let sourceY = targetYs[source] + dy;
        let minTarget = 0;
        let maxTarget = targetYs.length;

        while (minTarget < maxTarget - 1) {
            const mid = Math.floor((minTarget + maxTarget) / 2);
            if (targetYs[mid] < sourceY) {
                minTarget = mid;
            } else if (targetYs[mid] == sourceY) {
                minTarget = mid;
                maxTarget = mid + 1;
            } else {
                maxTarget = mid;
            }
        }

        // Search for the nearest available target
        let target;
        while (true) {
            if (minTarget !== -1 && (
                maxTarget === targetYs.length ||
                sourceY - targetYs[minTarget] <= targetYs[maxTarget] - sourceY
            )) {
                if (this.canReorderTo(e, source, minTarget)) {
                    target = minTarget;
                    break;
                }
                minTarget--;
            } else {
                if (this.canReorderTo(e, source, maxTarget)) {
                    target = maxTarget;
                    break;
                }
                maxTarget++;
            }
        }

        // Adjust styling
        for (let i = 0; i < rows.length; i++) {
            for (const node of rows[i]) {
                if (i === source) {
                    node.style.transform = `translate(${dx}px, ${dy}px)`;
                    node.style.filter = 'drop-shadow(0 0 1em rgba(0, 0, 0, 0.1))';
                    node.style.position = 'relative';
                    node.style.zIndex = '100';
                } else if (source < i && i <= target) {
                    node.style.transform = `translate(0px, ${-heights[source]}px)`;
                    node.style.transition = 'transform 300ms ease';
                } else if (target <= i && i < source) {
                    node.style.transform = `translate(0px, ${heights[source]}px)`;
                    node.style.transition = 'transform 300ms ease';
                } else {
                    node.style.transform = '';
                    node.style.transition = 'transform 300ms ease';
                }
            }
        }

        return target;
    }

    @action stopReorder(e) {
        if (this.currReorder === null) {
            return;
        }

        const { source } = this.currReorder;
        const target = this.moveReorder(e);

        const rows = Array.from(this.getReorderRows());
        for (let i = 0; i < rows.length; i++) {
            for (const node of rows[i]) {
                if (i === source) {
                    node.style.transform = '';
                    node.style.filter = '';
                    node.style.position = '';
                    node.style.zIndex = '';
                } else {
                    node.style.transform = '';
                    node.style.transition = '';
                };
            }
        }

        this.currReorder = null;
        if (this.canReorderTo(e, source, target)) {
            this.reorder(e, source, target);
        }
    }

    renderLazyRow(item, i, items) {
        return (
            <LazyRow key={item.cid} overview={this} item={item} i={i} items={items} />
        );
    }

    renderRow(item, i) {
        const TableRow = this.TableRow;
        const selected = this.isItemSelected(item);
        const expandKey = this.expandedCells[item.cid]

        let expand, expandFirst, expandLast;
        if (expandKey === undefined) {
            // no expand
            expand = null;
        } else if (typeof expandKey === 'number') {
            // expanded cell
            expand = this.mappedSettings[expandKey] ?? null;
            expandFirst = expandKey === 0 && !this.multiSelection;
            expandLast = expandKey === this.mappedSettings.length - 1;
        } else if (typeof expandKey === 'string' && expandKey.startsWith('button:')) {
            // expanded button
            const index = parseInt(expandKey.slice('button:'.length));
            expand = this.finalButtons[index] ?? null;
            expandFirst = false;
            expandLast = false;
        } else {
            throw new Error(`unknown expand key: ${expandKey}`);
        }

        if (expand !== null && expand.expand === undefined) {
            expand = null;
        }

        const reorderable = this.reorderable && this.canReorder(i);

        return (
            <React.Fragment key={item.cid}>
                <TableRow deleted={item.deleted} {...this.transformRowProps(this.rowProps(item, i))} selected={selected}>
                    {this.multiSelection && (
                        <Table.Cell collapsing>
                            {this.renderCheckbox(item, selected)}
                        </Table.Cell>
                    )}
                    {this.mappedSettings.map((setting, j) => this.renderLazyCell(
                        item, setting, j, i
                    ))}
                    {(this.finalButtons.length > 0 || this.reorderable) && (
                        <Table.Cell collapsing singleLine textAlign="right">
                            {this.finalButtons.map((button, i) => this.renderButton.bind(this)(
                                item, button, i + this.mappedSettings.length,
                            ))}
                            {this.reorderable && this.renderReorderIcon(reorderable, this.startReorder)}
                        </Table.Cell>
                    )}
                </TableRow>
                {expand && (
                    <this.ExpandRow key={expandKey} first={expandFirst} last={expandLast} {...expand.expandProps ?? {}}>
                        <Table.Cell colSpan={this.cells}>
                            {expand.expand(item)}
                        </Table.Cell>
                    </this.ExpandRow>
                )}
            </React.Fragment>
        );
    }

    renderCheckbox(item, selected) {
        return (
            <StyledCheckbox
                data-test-overview-checkbox={item.id}
                checked={selected}
                onChange={(e, { checked }) => this.toggleItemSelection(item, checked)}
                onClick={(e) => e.stopPropagation()}
            />
        );
    }

    renderReorderIcon(reorderable, onMouseDown) {
        return (
            <Icon data-test-reorder-handle
                name="arrows alternate vertical"
                style={{ cursor: reorderable ? 'move' : 'not-allowed' }}
                disabled={!reorderable}
                onMouseDown={onMouseDown}
            />
        );
    }

    getCellValue(item, setting) {
        if (setting.attr === undefined) {
            return undefined
        }

        let val = '';

        if (typeof setting.attr === 'function') {
            val = setting.attr(item);
        } else {
            val = item[setting.attr];
        }
        // Format moments
        if (moment.isMoment(val)) {
            val = val.format(setting.momentFormat || this.DATE_FORMAT);
        }
        // Format booleans
        if (typeof val === 'boolean') {
            val = val ? <Icon name="check" /> : '';
        }

        return val;
    }

    renderLazyCell(item, setting, i, j) {
        return (
            <LazyCell key={i} overview={this} item={item} setting={setting} i={i} j={j} />
        );
    }

    renderCell(item, setting, i, j) {
        const val = this.getCellValue(item, setting);

        if (val !== undefined) {
            return (
                <this.TableCell key={i} top={j == 0} {...this.cellProps(item, setting, i)}>
                    {val}
                </this.TableCell>
            );
        }
        return null;
    }

    removeItem(item) {
        if (window.confirm(t('form.deleteConfirmation'))) {
            return item.delete();
        } else {
            return Promise.reject();
        }
    }

    restoreItem(item) {
        if (window.confirm(t('form.restoreConfirmation'))) {
            return item.restore().then(() => item.deleted = false);
        } else {
            return Promise.reject();
        }
    }

    renderButton(item, button, i) {
        if (typeof button === 'function') {
            button = { type: 'custom', callback: button };
        }

        let type = button.type;
        let show = button.show === undefined ? true : button.show;

        if (typeof type === 'function') {
            type = type(item, i);
        }

        if (typeof show === 'function') {
            show = show(item, i);
        }

        if (!show) {
            return null;
        }

        switch (type) {
            case 'custom':
                return button.callback(item, i, this.itemButtonProps);
            case 'view':
                return (
                    <ItemButton
                        key={i}
                        data-test-view-button={item.getInternalId()}
                        icon="eye" label={button.viewLabel || t('tooltips.view')}
                        as={Link} to={button.to(item)}
                        {...this.itemButtonProps}
                    />
                );
            case 'edit':
                const buttonProps = (
                    this.EditModal
                        ? {}
                        : this.EditScreen
                            ? { as: Link, to: `${this.EditScreen.getUrl(item)}${this.generateSearchParams()}` }
                            : { as: Link, to: `${button.to(item)}${this.generateSearchParams()}` }
                );

                let buttonNode = (item.deleted && !button.editDeleted) ? (
                    <ItemButton
                        data-test-view-button={item.getInternalId()}
                        key={i}
                        icon="eye" label={button.viewLabel || t('tooltips.view')}
                        {...buttonProps}
                        {...this.itemButtonProps}
                    />
                ) : (
                    <ItemButton
                        data-test-edit-button={item.getInternalId()}
                        key={i}
                        icon="edit" label={button.editLabel || t('tooltips.edit')}
                        disabled={button.canEdit && !button.canEdit(item)}
                        {...buttonProps}
                        {...this.itemButtonProps}
                    />
                );

                if (this.EditModal) {
                    buttonNode = (
                        <this.EditModal
                            key={i}
                            trigger={buttonNode}
                            model={item}
                            afterDelete={(item) => this.store.models.remove(item)}
                            {...button.modalProps || {}}
                        />
                    );
                }

                return buttonNode;
            case 'hardDelete':
                return (
                    <ItemButton
                        key={i}
                        data-test-hard-delete-button={item.getInternalId()}
                        icon="delete" label={button.deleteLabel || t('tooltips.delete')}
                        disabled={button.canDelete && !button.canDelete(item)}
                        onClick={() => this.removeItem(item)}
                        {...this.itemButtonProps}
                    />
                );
            case 'delete':
                return (item.deleted) ? (
                    <ItemButton
                        key={i}
                        data-test-redo-button={item.getInternalId()}
                        icon="redo" label={button.restoreLabel || t('tooltips.restore')}
                        disabled={button.canRestore && !button.canRestore(item)}
                        onClick={() => this.restoreItem(item)}
                        {...this.itemButtonProps}
                    />
                ) : (
                    <ItemButton
                        key={i}
                        data-test-delete-button={item.getInternalId()}
                        icon="delete" label={button.deleteLabel || t('tooltips.delete')}
                        disabled={button.canDelete && !button.canDelete(item)}
                        onClick={() => this.removeItem(item)}
                        {...this.itemButtonProps}
                    />
                );
            case 'download':
                return (
                    <ItemButton
                        key={i}
                        icon="download" label={button.label}
                        as="a" href={button.href(item)}
                        {...this.itemButtonProps}
                    />
                );
            case 'expand':
                const key = `button:${i - this.mappedSettings.length}`;
                const toggled = this.expandedCells[item.cid] == key;

                let props = button.props ?? {};
                if (typeof props === 'function') {
                    props = props(item);
                }

                return (
                    <ExpandItemButton
                        key={i}
                        icon={button.icon} label={button.label}
                        active={toggled}
                        onClick={(e) => {
                            e.preventDefault();
                            this.toggleExpand(item.cid, key);
                        }}
                        {...this.itemButtonProps}
                        {...props}
                    />
                );
            default:
                return null;
        }
    }

    toggleSidebars(index) {
        if (this.sidebarActiveIndex === index) {
            this.sidebarActiveIndex = null;
        } else {
            this.sidebarActiveIndex = index;
        }
    }

    renderSidebarTrigger(props) {
        return <IconButton name="search" {...props} />;
    }

    renderSidebars() {
        return (
            <React.Fragment>
                {this.finalSidebars.map(({ trigger }, index) => (
                    <SidebarToggle
                        data-test-floating-sidebar-toggle={index}
                        index={index}
                        isActive={this.sidebarActiveIndex === index}
                        activeFgColor={this.toggleActiveFgColor}
                        fgColor={this.toggleFgColor}
                        activeBgColor={this.toggleActiveBgColor}
                        bgColor={this.toggleBgColor}
                        offset={this.sidebarsToggleTopOffset}
                    >
                        {trigger({ onClick: () => this.toggleSidebars(index) })}
                    </SidebarToggle>
                ))}
                <Sidebar
                    data-test-sidebar={this.sidebarActiveIndex}
                    show={this.sidebarActiveIndex !== null}
                    small={!!this.modal}
                    borderBottom={this.showDashboard !== null}
                    wrap={this.sidebarActiveIndex !== null && (this.finalSidebars[this.sidebarActiveIndex].wrap ?? true)}
                >
                    {this.sidebarActiveIndex !== null ? this.finalSidebars[this.sidebarActiveIndex].content() : null}
                </Sidebar>
            </React.Fragment>
        );
    }

    renderSidebarContent() {
        return (
            <Form>
                {this.finalFilters.map(this.renderFilter.bind(this))}
            </Form>
        );
    }

    // Move the store back to the first page
    setToFirstPage() {
        if(this.store.currentPage !== 1) {
            this.store.setPage(1)
        }
    }

    renderFilter(filter, i) {
        if (typeof filter === 'function') {
            filter = filter();
        }

        let { type, targetProps = {}, afterChange, ...props } = filter;

        if (type === 'custom') {
            const { callback, ...args } = props;
            return callback(args, i);
        }

        if (type === 'group') {
            let filters = props.filters || [];
            delete props.filters;

            let label = props.label || null;
            delete props.label;

            let group = (
                <SmallFormGroup key={i} {...props} {...targetProps}>
                    {filters.map(this.renderFilter.bind(this))}
                </SmallFormGroup>
            );

            if (label !== null) {
                group = (
                    <Form.Field>
                        <label>{label}</label>
                        {group}
                    </Form.Field>
                );
            }

            return group;
        }

        const Target = TARGETS[type] ?? type;

        const filterFetch = (
            DEBOUNCE[type]
                ? this.debouncedFetch
                : this.fetch.bind(this)
        );

        const combinedAfterChange = action((...args) => {
            afterChange && afterChange(...args);
            this.store.setPage(1, { fetch: false });
            filterFetch(...args);
        });

        const defaultProps = DEFAULT_PROPS[type] || {};

        return (
            <Target
                key={i}
                target={this.store}
                afterChange={combinedAfterChange}
                autoComplete="off"
                {...defaultProps}
                {...props}
                {...targetProps}
            />
        );
    }

    renderStat({ label, source, format = (x) => x }) {
        let value;
        if (this.store.isLoading) {
            value = (
                <SidebarStatLoader active inline />
            );
        } else {
            if (typeof source === 'string') {
                const metaKey = source;
                source = (store) => store.meta[metaKey];
            }

            value = source(this.store);
            if (value === undefined || value === null || isNaN(value)) {
                value = '-';
            } else {
                value = format(value);
            }
        }

        return (
            <SidebarStat>
                <SidebarStatLabel>{label}</SidebarStatLabel>
                <SidebarStatValue>{value}</SidebarStatValue>
            </SidebarStat>
        );
    }

    renderStats() {
        return this.stats.map(this.renderStat.bind(this));
    }

    @action
    setPaginationLimitLocalStorage = (value) => {
        this.paginationLimit = value;
        localStorage.setItem(`pagination-limit-${this.myFilterKey}`, this.paginationLimit);
    }

    renderPaginationControls() {
        return (
            <PaginationControls store={this.store} showLimit={this.showPaginationLimit} value={this.paginationLimit}
                setLocalStorage={this.setPaginationLimitLocalStorage}
            />
        );
    }

    @computed get finalToolbar() {
        let toolbar = this.getToolbar();

        if (typeof toolbar === 'function') {
            toolbar = toolbar();
        }

        return toolbar ? toolbar.slice() : [];
    }

    handleRenderTypeChange = (value) => {
        this.selectedRenderType = value;
        localStorage.setItem(`render-type-${this.myFilterKey}`, JSON.stringify(this.selectedRenderType));
        this.itemButtonProps = { size: this.selectedRenderType === this.RENDER_TYPE_COMPACT ? 'mini' : 'small' };
    }

    renderTypeChangeDropdown = () => {
        return (
            <StyledMenu pagination size="mini">
                <Menu.Item
                >
                    {t('common.overviewRender.mode')}
                </Menu.Item>
                <Menu.Item
                >
                    <Dropdown
                        upward
                        options={this.RENDER_TYPE_OPTIONS}
                        onChange={(e, { value }) => this.handleRenderTypeChange(value)}
                        value={this.selectedRenderType}
                        data-test-render-mode
                    />
                </Menu.Item>
            </StyledMenu>
        )
    }

    renderToolbar() {
        const toolbar = this.finalToolbar;
        const toolbarButtons = this.renderToolbarButtons();

        if (!toolbar) {
            return null;
        }

        return (
            <this.Toolbar>
                {this.finalDashboards.length > 0 && (
                    <StyledTargetRadioButtons
                        value={this.showDashboard}
                        onChange={(value) => this.showDashboard = value}
                        options={this.showDashboardOptions}
                    />
                )}
                {this.showDashboard !== null && (
                    <StyledTargetRadioButtons icon
                        value={this.splitDashboard}
                        onChange={(value) => this.splitDashboard = value}
                        options={SPLIT_DASHBOARD_OPTIONS}
                    />
                )}
                {this.showTable && (
                    <>
                        {!this.lazyLoading && this.renderPaginationControls()}
                        {this.showRenderTypeToggle ? this.renderTypeChangeDropdown() : null}
                    </>
                )}
                <RightDivider />
                {toolbarButtons && (
                    <ResponsiveContainer>
                        {toolbarButtons}
                    </ResponsiveContainer>
                )}
            </this.Toolbar>
        );
    }

    @computed get finalBulkActions() {
      let bulkActions = this.getBulkActions()

      if (typeof bulkActions === 'function') {
          bulkActions = bulkActions();
      }

      return bulkActions ? bulkActions.slice() : [];
  }

    renderToolbarButtons() {
        const toolBarButtons = this.finalToolbar.map(this.renderToolbarItem.bind(this))

        if (this.multiSelection && this.finalBulkActions.length > 0) {
            const bulkActionsButton = (<BulkActionsButton data-test-bulk-action-button
                text={`Choose bulk action (${this.getSelection().length})`}
                store={this.selectionStore}
                afterBulkAction={this.toggleAllSelection.bind(this)}
                actions={this.finalBulkActions}
                anchorRight={true}
            />)
            toolBarButtons.push(bulkActionsButton);
        }

        return toolBarButtons;
    }

    renderToolbarItem(item, i) {
        if (typeof item === 'function') {
            return item(i);
        }

        switch (item.type) {
            case 'custom':
                return item.callback();
            case 'add':
                if (item.label === undefined) {
                    item.label = t('form.addButton');
                }

                const buttonProps = (
                    this.EditModal
                        ? {}
                        : this.EditScreen
                            ? { as: Link, to: `${this.EditScreen.getUrl()}${this.generateSearchParams()}` }
                            : { as: Link, to: `${item.to}${this.generateSearchParams()}` }
                );

                let buttonNode = (
                    <ToolbarButton data-test-toolbar-add-button
                        key={i}
                        icon="add" content={item.label}
                        {...buttonProps}
                    />
                );

                if (this.EditModal) {
                    buttonNode = (
                        <this.EditModal
                            key={i}
                            trigger={buttonNode}
                            afterSave={() => this.fetch()}
                            {...item.modalProps || {}}
                        />
                    );
                }

                return buttonNode;
            case 'view':
                return (
                    <ToolbarButton
                        key={i}
                        icon="view" content={item.label}
                        as={Link}
                        to={`${item.to}${this.generateSearchParams()}`}
                    />
                );
            case 'download':
                return (
                    <ToolbarButton
                        key={i}
                        icon="download" content={item.label}
                        as={Link}
                        to={`${item.to}${this.generateSearchParams()}`}
                    />
                );
            default:
                return null;
        }
    }

    canReorder(source) {
        return true;
    }

    canReorderTo(e, source, target) {
        return true;
    }

    reorder(e, source, target) {
        const [model] = this.store.models.splice(source, 1);
        this.store.models.splice(target, 0, model);
    }
}

@observer
class LazyRow extends Component {
    static propTypes = {
        overview: PropTypes.instanceOf(AdminOverview).isRequired,
        item: PropTypes.object.isRequired,
        i: PropTypes.number.isRequired,
        items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    };

    render() {
        const { overview, item, i, items } = this.props;
        return overview.renderRow(item, i, items);
    }
}

@observer
class LazyCell extends Component {
    static propTypes = {
        overview: PropTypes.instanceOf(AdminOverview).isRequired,
        item: PropTypes.object.isRequired,
        setting: PropTypes.object.isRequired,
        i: PropTypes.number.isRequired,
        j: PropTypes.number.isRequired,
    };

    render() {
        const { overview, item, setting, i, j } = this.props;
        return overview.renderCell(item, setting, i, j);
    }
}
