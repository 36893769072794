import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Defect } from 'store/Defect';

export class Image extends Model {
    static backendResourceName = 'image';

    @observable id = null;
    @observable file = null;

    relations() {
        return {
            defect: Defect,
        };
    }
}

export class ImageStore extends Store {
    Model = Image;
    static backendResourceName = 'image';
}